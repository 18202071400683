import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowswungicon.svg'
import {toggleShowBanner} from './../../actions'


function Topbanner(props) {
	const prevScrollRef = useRef();

	useEffect(() => {
		prevScrollRef.current = props.scrollPos

		if (props.showBanner && prevScrollRef.current > prevScrollState && props.scrollPos > 100) {
			toggleShowBanner(false);
			// props.dispatch({type: 'TOGGLE_SHOW_BANNER', val: false})
		} else if (!props.showBanner && prevScrollRef.current < prevScrollState && props.scrollPos < 350) {
			toggleShowBanner(true);
			// props.dispatch({type: 'TOGGLE_SHOW_BANNER', val: true})
		}
	}, [props.scrollPos])

	const prevScrollState = prevScrollRef.current;

  return (
    <div className={`Topbanner ${props.showBanner ? 'showbanner' : ''} ${props.animateBanner ? 'animate' : ''} ${props.mainmenuIsOpen ? 'open' : ''}`}>
    	<a href="https://preseedventures.dk">Go to PreSeed Ventures<ArrowIcon/></a>
    </div>
  );
}

export default connect(state => ({
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	showBanner: state.App.showBanner,
	animateBanner: state.App.animateBanner,
	scrollPos: state.App.scrollPosition
}))(Topbanner);